const property = {
	label: 'Number of Guests',
	type: 'object',
	values: [
		{
			display: '0–50',
			value: {
				minGuestCount: 0,
				maxGuestCount: 50,
			},
		},
		{
			display: '51–100',
			value: {
				minGuestCount: 51,
				maxGuestCount: 100,
			},
		},
		{
			display: '101–150',
			value: {
				minGuestCount: 101,
				maxGuestCount: 150,
			},
		},
		{
			display: '151–200',
			value: {
				minGuestCount: 151,
				maxGuestCount: 200,
			},
		},
		{
			display: '201–300',
			value: {
				minGuestCount: 201,
				maxGuestCount: 300,
			},
		},
		{
			display: '300+',
			value: {
				minGuestCount: 301,
				maxGuestCount: 0,
			},
		},
	],
	minProperties: 1,
	required: true,
	messages: {
		required: 'Please provide number of guests',
	},
};

const ui = {
	'ui:field': 'xo_drop_down',
	'ui:placeholder': 'Number of Guests',
	classNames: 'col-xs-12 col-md-6',
};

const optimizelyUi = {
	'ui:field': 'xo_drop_down',
	'ui:placeholder': 'Number of Guests',
	classNames: 'col-xs-12',
};

const propertyName = 'guest_count';

module.exports = {
	property,
	ui,
	optimizelyUi,
	propertyName,
};
